import * as React from "react"
import BlockParser from "../components/block-parser";
import Layout from "../components/layout/layout";

export interface IPage {
    id: string,
    title: string,
    pageContext: {
        blocks: [IBlock],
        isFrontPage: boolean
    }
}

export interface IBlock {
    name: string,
    data: any
}

const Page = (page: IPage) => {
    return (
        <Layout isFrontPage={page.pageContext.isFrontPage}>
            <BlockParser blocks={page.pageContext.blocks}/>
        </Layout>
    )
}

export default Page
